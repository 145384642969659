.home-page {
    background-color: #edecf2;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;


}

.menu-card {
    border: solid 1px transparent;
    border-radius: 10px;
    background-color: white;
    margin: 10px;

    display: flex;

    .menu-card__img {
        width: 25%;
        position: relative;
    }

    .menu-card__content {
        width: 70%;
        position: relative;
    }

    .menu-card__caret {
        width: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

.trust-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px 5px;
    align-items: center;

    .trust-item {
        img {
            width: 40px;
            height: 40px;
        }
    }

    .trust-item2 {
        img {
            width: 80pt;
        }
    }
}

.guide-button {
    border: solid 1px white;
    border-radius: 10px;
    color: white;
    display: flex;
    justify-content: center;
}

.home-page__footer {
    background-color: white;
    //background-color: #edecf2;
    -webkit-box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.21);
    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.21);
    z-index: 100;
}

.row-btn {
    display: flex;
    justify-content: space-around;

}

.row-btn-money {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #d3c8c8;
    box-shadow: 2px 2px;
}

.shadow-light {
    box-shadow: 1px 1px 5px 1px #7f7878;
}

.row-btn-text {
    color: #861bff;

}

.container-soxo-btn {
    display: inline;

}

.container-soxo-btn {
    display: flex;
    // justify-content: center;
    flex-wrap: wrap;
}

.box-w-btn {
    background-color: #edecf2;
    width: 50%;

    padding: 10px;

}

.img-logo {
    width: 60px;
    height: 35px;
    border: 1px solid#ebe9ee;
    border-right-width: 1px;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    padding-right: 18px;
    object-fit: contain;
}

.box-soxo-btn {
    height: 120px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid#ebe9ee;
    box-shadow: 1px 2px 3px 4px #c7c7c9;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.center-btn {
    display: flex;
    justify-content: space-evenly;
}

.time-btn {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    font-size: 13px;
}

.box-or-time {
    background-color: #1466ae;
    font-size: 10px;
    padding: 0px 10px 0px 10px;
    border-radius: 5px;
    color: white;
    font-family: 'Montserrat-SemiBold';
    display: flex;
    align-items: center;
}

.fnt-size-13 {
    font-size: 12px;
    font-weight: bold;
    color: #26356c;
}

.fnt-size-13-or {
    font-size: 10px;

    color: #1466ae;
    font-family: 'Montserrat-MediumItalic'
}

.fnt-size-18 {
    font-size: 13px;
    color: #38ab9e;
    font-family: 'Montserrat-Black';
    font-weight: 300;
}

.value-btn {
    background-color: #38ab9e;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 10px;
    border-radius: 5px;
    color: white;
}

.pd-10 {
    padding: 5px 10px 5px 10px;
    font-family: 'Montserrat-MediumItalic'
}

.btn-download {
    display: none;
}

@media (max-width: 498px) {
    .btn-download {
        position: fixed;
        right: 10px;
        bottom: 100px;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        display: -moz-box;
        display: flex;
        -moz-box-pack: center;
        justify-content: center;
        -moz-box-align: center;
        align-items: center;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        z-index: 999;
        background-color: yellow;
        text-decoration: none;
        color: #000;
    }
}

.bgNav {
    background-image: url('/header_blue.png');
    background-size: cover;
}

.bgSidebar {
    background-image: url('/header1_Blue.jpg');
    background-size: cover;
}

.livenow {
    width: 24px;
    // padding: 10px 40px 12px 40px;
    margin: 0 auto;
    height: 24px;

}

.livenow>div {
    vertical-align: middle;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: absolute;
    margin: 0 auto;
    border: 3px solid #1df15d;
    -webkit-animation: live 1.4s infinite ease-in-out;
    animation: live 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    &:nth-child(1) {
        background-color: rgba(255, 255, 255, 0.3);
        background-color: #1df15d;
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
    }

    &:nth-child(2) {
        -webkit-animation-delay: 0.16s;
        animation-delay: 0.16s;
    }

    &:nth-child(3) {
        -webkit-animation-delay: 0.42s;
        animation-delay: 0.42s;
        border: 3px solid rgba(255, 255, 255, 0.87);
    }

    &:nth-child(4) {
        border: 3px solid #1df15d;
        -webkit-animation-delay: -0.42s;
        animation-delay: -0.42s;
    }
}

@-webkit-keyframes live {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0.6)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes live {

    0%,
    80%,
    100% {
        transform: scale(0.6);
        -webkit-transform: scale(0.6);
    }

    40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}